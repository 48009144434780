require('./_colorbox.scss');

(function ($) {
    let resizeModalFunc = null;
    const debounce = (func, wait, immediate) => {
        let timeout;
        return function() {
            const context = this, args = arguments;
            const later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    $(function () {
        $(window).on("csn-closeModalBox", function () {
            $.colorbox.close();
        });

        $(document).on("click", "*[data-toggle=hyperlink]", function () {
            window.open($(this).data("targetUrl"), "_blank");
        });

        $(document).on("click", "*[data-toggle='lightbox']", function () {
            const $this = $(this);
            const url = $this.data("target-url");
            if (url === undefined || url === null || url === "") return;
            const isId = url.indexOf("#") === 0;
            const lightboxWidth = $this.data("lightbox-width") ? parseInt($this.data("lightbox-width")) : 800;
            const lightboxHeight = $this.data("lightbox-height") ? parseInt($this.data("lightbox-height")) : 600;
            const minWidth = $this.data("lightbox-minwidth") ? parseInt($this.data("lightbox-minwidth")) : 800;
            const minHeight = $this.data("lightbox-minheight") ? parseInt($this.data("lightbox-minheight")) : 200;
            const className = $this.data("lightbox-classname") ? $this.data("lightbox-classname") : false;
            const onClosed = $this.data("lightbox-onclosed") ? window[$this.data("lightbox-onclosed")] : false;
            const getModalWidth = () => {
                return lightboxWidth > 0
                    ? lightboxWidth > window.innerWidth ? window.innerWidth : lightboxWidth
                    : lightboxWidth + window.innerWidth;
            };
            const getModalHeight = () => {
                return lightboxHeight > 0
                    ? lightboxHeight > window.innerHeight ? window.innerHeight : lightboxHeight
                    : lightboxHeight + window.innerHeight;
            };
            const width = getModalWidth();
            const height = getModalHeight();
            const isResize = lightboxWidth < 0 || lightboxHeight < 0; // Smaller than 0 means its relative to window inner size

            const config = {
                href: url,
                iframe: !isId,
                inline: isId,
                width:  minWidth && minWidth > width ? minWidth : width,
                height: minHeight && minHeight > height ? minHeight : height,
                opacity: 0.95,
                fixed: true,
                reposition: true,
                className: className,
                onClosed: onClosed,
                fastIframe: false
            };

            if (config.iframe && $this.data("lightbox-fastIframe"))
                config.fastIframe = $this.data("lightbox-fastIframe") === "true";

            $.colorbox(config);
        
            if (isResize) {
                resizeModalFunc = debounce(function() {
                    const widthResized = getModalWidth();
                    const heightResized = getModalHeight();
                    $.colorbox.resize({
                        width: minWidth && minWidth > widthResized ? minWidth : widthResized,
                        height: minHeight && minHeight > heightResized ? minHeight : heightResized
                    });
                }, 250, false);

                window.addEventListener("resize", resizeModalFunc);
            }
        });
    });

    $(document)
        .bind("cbox_complete",
            function() {
                $("body").addClass("fixed");
            })
        .bind("cbox_cleanup",
            function() {
                $("body").removeClass("fixed");
                if (resizeModalFunc) {
                    window.removeEventListener("resize", resizeModalFunc);
                    resizeModalFunc = null;
                }
            });
})(jQuery);
